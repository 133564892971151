import { crmApi } from './api'

const usersApi = crmApi.injectEndpoints({

  endpoints: (builder) => ({

    getUsers: builder.query({
      query: () => 'users',
      providesTags: ['User'],
    }),

    addUser: builder.mutation({
      query: (body) => ({
        url: 'users',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result) => (result ? ['User'] : []),
    }),

    getUser: builder.query({
      query: (id) => `users/${id}`,
     providesTags: (id) => [{ type: 'User', id }],
    }),

    updateUser: builder.mutation({
      query: (body) => ({
        url: `users/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (result) => (result ? ['User'] : []),
    }),

    getUserCalls: builder.query({
      query: (id) => `users/${id}/calls`,
    }),

  }),
})



export const {
  useGetUsersQuery,
  useAddUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useGetUserCallsQuery,
} = usersApi