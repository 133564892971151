import { useGetUsersQuery } from '../../app/api/usersApi'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import AddUser from '../../components/User/AddUser'
import UsersList from '../../components/User/UsersList'
import Search from '../../features/Search/Search'
import { SpinnerBig } from '../../features/Spinner/Spinner'
import { useState } from 'react'
import { PeopleFill } from 'react-bootstrap-icons'

export default function UsersPage() {

    const { data: users, isLoading, isFetching } = useGetUsersQuery()

    const [searchValue, setSearchValue] = useState()

    const totalUserCount = users?.length || 0

    return (
        <Container fluid className='h-100'>
            <h5><PeopleFill size={30} /> Клієнти ({totalUserCount})</h5>
            <Row>
                <Col className='mb-4'
                    xs={{ span: 12, order: 'last' }}
                    sm={{ span: 12, order: 'last' }}
                    md={{ span: 6, order: 'first' }}
                    lg={5} xl={4} xxl={3}>
                    <Search setSearch={setSearchValue} />
                </Col>
                <Col className='text-end mb-4'>
                    <AddUser />
                </Col>
            </Row>
            {(isLoading || isFetching) ? <SpinnerBig /> :
            users && <UsersList users={users} search={searchValue} />}
        </Container>
    )

}
