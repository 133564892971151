import Badge from 'react-bootstrap/Badge'
import { useGetMissedCountQuery } from '../../app/api/callsApi'



export default function MissCallCollapsBadge() {

    const { data: misscalls } = useGetMissedCountQuery(undefined, {
        selectFromResult: ({ data }) => ({
            data: data
           }),
        pollingInterval: 5000, })

    return (
        <Badge pill bg="warning" text="dark"
            style={{ position: 'absolute', right: '5px', top: '5px', fontSize: '9px' }}
        >{misscalls?.count !== 0 && misscalls?.count}</Badge>
    )

}

export function MissCallBadge() {

    const { data: misscalls } = useGetMissedCountQuery(undefined, {
        selectFromResult: ({ data }) => ({
            data: data
           }),
        pollingInterval: 5000, })
        
    return (
        <Badge pill bg="warning" text="dark"
            style={{ fontSize: '11px' }}
        >{misscalls?.count !== 0 && misscalls?.count}</Badge>
    )

}