import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'


const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_CRM_API_URL + '/api/',
  credentials: "include",
})

const baseQueryWithAuth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    Cookies.remove('isLogged')
  }
  return result;
}



export const crmApi = createApi({
  reducerPath: "crmApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: [
    'User',
    'Product',
    'Brand',
    'Order',
    'OrderStatus',
    'Channel',
    'Delivery',
    'Payment',
  ],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({}),
});

