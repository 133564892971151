import { useAddUserMutation } from '../../app/api/usersApi'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, useFieldArray } from "react-hook-form"
import { DashSquare, PlusSquare, PersonAdd } from 'react-bootstrap-icons'
import SpinnerSmall from '../../features/Spinner/Spinner'
import ErrorMessage from '../../features/ErrorMessage/ErrorMessage'


export default function AddUser(props) {
    const [show, setShow] = useState(false)

    const { register, handleSubmit, control } = useForm({
        defaultValues: {
            UserPhone: [{ phone: props.number || "" }]
        }
    })
    const { fields, append, remove } = useFieldArray({ control, name: "UserPhone" })

    const [addUser, { isLoading, isError, error }] = useAddUserMutation()

    const navigate = useNavigate()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSubmit = (formdata) => {
        addUser(formdata).unwrap()
            .then((payload) => navigate("/user/" + payload.id))
            .catch((error) => console.log(error));
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <PersonAdd size={25} /> Додати клієнта
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onSubmit)} >
                    <Modal.Header closeButton>
                        <Modal.Title><h5><PersonAdd size={25} /> Додати клієнта</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isError && <ErrorMessage error={error} />}
                        <Form.Group className="mb-3">
                            <Form.Label className='text-secondary'>Прізвище</Form.Label>
                            <Form.Control {...register("lastname")} type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='text-secondary'>Ім'я *</Form.Label>
                            <Form.Control {...register("firstname")} type="text" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='text-secondary'>По батькові</Form.Label>
                            <Form.Control {...register("surname")} type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='text-secondary'>e-mail</Form.Label>
                            <Form.Control {...register("email")} type="email" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className='text-secondary'>Телефон *</Form.Label>
                            {fields.map((item, index) => (
                                <Row key={item.id}>
                                    <Col><Form.Control className="mb-2"
                                        {...register(`UserPhone.${index}.phone`,
                                            { setValueAs: value => value.replace(/\D/g, '').slice(-10) })}
                                        type="text" required /></Col>
                                    <Col>{index > 0 && <Button variant="light" className='text-secondary'
                                        onClick={() => remove(index)}><DashSquare size={20} /></Button>}</Col>
                                </Row>
                            ))}
                            <Button variant="light" className='text-secondary' onClick={() => append()}><PlusSquare size={20} /> Додатковий номер</Button>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            ❌ Скасувати
                        </Button>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {isLoading ? <SpinnerSmall /> : '✔️'} Зберегти
                        </Button>
                    </Modal.Footer>
                </Form >
            </Modal >
        </>
    );
}