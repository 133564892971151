import { isToday, isYesterday, parseISO, format, intervalToDuration, formatDuration } from 'date-fns'

export default function DateTime(props) {
    return (
        <>
            {isToday(parseISO(props.date)) && 'сьогодні'}
            {isYesterday(parseISO(props.date)) && 'вчора'}
            {(!isToday(parseISO(props.date)) && !isYesterday(parseISO(props.date))) && format(parseISO(props.date), 'd.MM.yyyy')}
            {' ' + format(parseISO(props.date), 'H:mm')}
        </>
    )

}