import { useGetUserByphoneQuery } from '../../app/api/callsApi'
import { TelephoneInboundFill, TelephoneOutboundFill, TelephoneXFill, PersonBoundingBox, ExclamationCircleFill } from 'react-bootstrap-icons'
import { SpinnerSmallBlue } from '../../features/Spinner/Spinner'
import { Link } from 'react-router-dom'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';



export default function UserByPhone(props) {

    const { data: user, isLoading, isFetching } = useGetUserByphoneQuery(props.number)

    if (isLoading || isFetching) return <SpinnerSmallBlue />

    if (!user) return null

    return (
        <>
            <Link to={`/user/${user.id}`} className='text-decoration-none text-center'>
                <PersonBoundingBox size={25} color='#adb5bd' className='mx-2' />{(user.lastname ? user.lastname : '') + ' ' + user.firstname}
            </Link>
        </>
    );
}


export function UserByPhoneOrder(props) {

    const { data: user, isLoading, isFetching } = useGetUserByphoneQuery(props.number)

    if (isLoading || isFetching) return <SpinnerSmallBlue />

    if (!user) return null

    return (
        <>
            {props.lastname === user.lastname && props.firstname === user.firstname ?
                <Link to={`/user/${user.id}`} className='text-decoration-none text-center'>
                    <PersonBoundingBox size={25} color='#adb5bd' className='mx-2' />{(user.lastname ? user.lastname : '') + ' ' + user.firstname}
                </Link>
                :
                <Link to={`/user/${user.id}`} className='text-decoration-none text-center'>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip><i>Дані замовлення і бази клієнтів відрізняюються!</i><br />
                            Замовлення:<br />
                            <strong>{props.lastname} {props.firstname}</strong><br />
                            База клієнтів:<br />
                            <strong>{user.lastname} {user.firstname}</strong>
                        </Tooltip>}
                    ><ExclamationCircleFill size={20} color='red' className='mx-2' /></OverlayTrigger>{(props.lastname ? props.lastname : '') + ' ' + props.firstname}
                </Link>
            }
        </>
    );
}