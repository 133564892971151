import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useForm, useFieldArray } from "react-hook-form"
import { useUpdateOrderStatusesMutation } from '../../app/api/ordersApi'
import Button from 'react-bootstrap/Button'
import { PlusSquare } from 'react-bootstrap-icons'
import SpinnerSmall from '../../features/Spinner/Spinner'
import ErrorMessage from '../../features/ErrorMessage/ErrorMessage'


export default function OrderStatuses(props) {

    const { register, handleSubmit, control } = useForm({
        defaultValues: { orderStatuses: props.statuses }
    })

    const { fields, append } = useFieldArray({ control, name: "orderStatuses" })

    const [updateStatuses, { isLoading, isError, error }] = useUpdateOrderStatusesMutation()

    const onSubmit = (formdata) => {
        updateStatuses(formdata).unwrap()
            //.then((playload) => console.log(playload))
            .catch((error) => console.log(error));
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} >
            {isError && <ErrorMessage error={error} />}
            <Form.Group>
                <Row>
                    <Col md="4" xl="3"><Form.Label className='text-secondary'>Статус *</Form.Label></Col>
                </Row>
                {fields.map((item, index) => (
                    <Row key={item.id} >
                        <Col md="4" xl="3"><Form.Control className="mb-2"
                            {...register(`orderStatuses.${index}.status`)} type="text" required /></Col>
                    </Row>
                ))}
                <Button variant="light" className='text-secondary mb-4' onClick={() => append()}><PlusSquare size={20} /> Додати статус</Button>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? <SpinnerSmall /> : '✔️'} Зберегти
            </Button>
        </Form >
    );
}
