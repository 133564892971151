import Container from 'react-bootstrap/Container'
import Channels from '../../components/Channels/Channels'
import { useGetChannelsQuery } from '../../app/api/ordersApi'
import { SpinnerBig } from '../../features/Spinner/Spinner'




export default function ChannelsPage() {

    const { data: channels, isLoading, isFetching } = useGetChannelsQuery()

    const totalChannelCount = channels?.length || 0

    return (
        <Container fluid className='h-100'>
            <h5>Канали продажів ({totalChannelCount})</h5>
            {(isLoading || isFetching) ? <SpinnerBig /> :
                channels && <Channels channels={channels} />}
        </Container>
    )

}


