import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { SinglePhone } from '../User/PhoneList'
import Badge from 'react-bootstrap/Badge'
import { useGetUserByphoneQuery } from '../../app/api/callsApi'
import { SpinnerSmallBlue } from '../../features/Spinner/Spinner'
import DateTime from '../../features/DateTime/DateTime'
import soccerpoint from '../../assets/images/soccerpoint_logo.png'
import { UserByPhoneOrder } from '../User/UserByPhone'
import Table from 'react-bootstrap/Table'





export default function OrdersList(props) {

    const isXS = useMediaQuery({ query: '(max-width: 575px)' })
    const isSM = useMediaQuery({ query: '(min-width: 576px)' })
    const isMD = useMediaQuery({ query: '(min-width: 768px)' })
    const isLG = useMediaQuery({ query: '(min-width: 992px)' })
    const isXL = useMediaQuery({ query: '(min-width: 1200px)' })
    const isXXL = useMediaQuery({ query: '(min-width: 1400px)' })

    let itemsPerPage, pageRangeDisplayed, marginPagesDisplayed, forcePage

    if (isXS) {
        itemsPerPage = 10
        pageRangeDisplayed = 1
        marginPagesDisplayed = 1
    }
    if (isSM) {
        itemsPerPage = 20
        pageRangeDisplayed = 1
        marginPagesDisplayed = 1
    }
    if (isMD) {
        itemsPerPage = 30
        pageRangeDisplayed = 2
        marginPagesDisplayed = 1
    }
    if (isLG) {
        itemsPerPage = 40
        pageRangeDisplayed = 2
        marginPagesDisplayed = 2
    }
    if (isXL) {
        itemsPerPage = 50
        pageRangeDisplayed = 3
        marginPagesDisplayed = 3
    }
    if (isXXL) {
        itemsPerPage = 60
        pageRangeDisplayed = 5
        marginPagesDisplayed = 3
    }

    const searchValue = props.search

    if (searchValue) {
        forcePage = 0
    }

    const filteredData = props.orders.filter((item) => {
        if (!searchValue) return props.orders
        return (
            item.lastname?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item.firstname?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item.surname?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item.email?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item.UserPhone?.some(phones => phones.phone?.includes(searchValue?.replace(/\D/g, '').slice(-10)))
        )
    })

    const [itemOffset, setItemOffset] = useState(0)
    const endOffset = itemOffset + itemsPerPage
    const currentItems = filteredData.slice(itemOffset, endOffset)
    const pageCount = filteredData ? Math.ceil(filteredData.length / itemsPerPage) : 0

    useEffect(() => {
        setItemOffset(0)
    }, [searchValue])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredData.length
        setItemOffset(newOffset)
        window.scrollTo(0, 0)
    };


    return (
        <>
            <Row xs={1}>
                <Items currentItems={currentItems} />
            </Row>
            <Row className='mt-4'>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={pageRangeDisplayed}
                    marginPagesDisplayed={marginPagesDisplayed}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={forcePage}
                /></Row>
        </>
    );
}


function Items({ currentItems }) {

    return (
        <>
            <Row className='text-center d-none d-md-flex'>
                <Col xs={12} md={1}><Form.Label className='text-secondary'>Номер замовлення</Form.Label></Col>
                <Col xs={12} md={2}><Form.Label className='text-secondary'>Клієнт</Form.Label></Col>
                <Col xs={12} md={1}><Form.Label className='text-secondary'>Канал продажу</Form.Label></Col>
                <Col xs={12} md={1}><Form.Label className='text-secondary'>Дата</Form.Label></Col>
                <Col xs={12} md={1}><Form.Label className='text-secondary'>Статус</Form.Label></Col>
                <Col xs={12} md={1}><Form.Label className='text-secondary'>Доставка</Form.Label></Col>
                <Col xs={12} md={2}><Form.Label className='text-secondary'>Оплпата</Form.Label></Col>
                <Col xs={12} md={1}><Form.Label className='text-secondary'>Сума замовлення</Form.Label></Col>
            </Row>
            {currentItems &&
                currentItems.map((item) => (
                    <Col key={item.id} className='py-1'>
                        <div className={'w-auto h-100 shadow p-3 rounded-3 border bg-white hovereffect'}>
                            <Row className='text-center w-100'>
                                <Col>
                                    <Row>
                                        <Col xs={12} md={2} className='align-self-center'>
                                            <Link to={`/order/${item.id}`} >{item.id}</Link>
                                        </Col>

                                        <Col xs={12} md={2} className='align-self-center'>
                                            <Row><Col>{item.channel.channel === 'soccerpoint' && <img src={soccerpoint} height={20} alt='soccerpoint' />}</Col></Row>
                                            <Row className='text-secondary mt-1' style={{ 'font-size': '0.9em' }}><Col>{item.externalId}</Col></Row>
                                        </Col>
                                        <Col xs={12} md={2} className='align-self-center ' style={{ 'font-size': '0.9em' }}><DateTime date={item.createdAt} /></Col>
                                        <Col xs={12} md={2} className='align-self-center'><Badge>{item.status.status}</Badge></Col>
                                        <Col xs={12} md={4} className='align-self-center'>
                                            <Row><Col><UserByPhoneOrder firstname={item.firstname} lastname={item.lastname} number={item.phone} /></Col></Row>
                                            <Row className='mt-1'><Col><SinglePhone number={item.phone} /></Col></Row>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={12} md={1} className='align-self-center'>{item.delivery.delivery}</Col>
                                <Col xs={12} md={2} className='align-self-center'>{item.payment.payment}</Col>
                                <Col xs={12} md={1} className='align-self-center'>
                                    {item.OrderProducts.reduce((prev, product) => prev + product.price * product.quantity, 0)} грн.
                                </Col>
                            </Row>
                            <Row className='text-center w-100'>
                                <Col>
                                    <Table border striped className='border-top mt-3' style={{ 'font-size': '0.7em', 'margin-bottom': 0 }}>
                                        <thead>
                                            <tr>
                                                <th>Фото</th>
                                                <th>Артикул</th>
                                                <th>Назва</th>
                                                <th>Ціна</th>
                                                <th>Розмір</th>
                                                <th>Кількість</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item.OrderProducts.map((product) => (
                                                <tr>
                                                    <td></td>
                                                    <td>{product.sku}</td>
                                                    <td>{product.name}</td>
                                                    <td>{product.price} грн.</td>
                                                    <td>{product.size}</td>
                                                    <td>{product.quantity} шт.</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                        </div>
                    </Col>


                ))
            }
        </>
    );
}

