import { Telephone } from 'react-bootstrap-icons'
import s from './PhoneList.module.css'
import { useUpdateRingOutMutation } from '../../app/api/callsApi'
import { useGetUserByphoneQuery } from '../../app/api/callsApi'

export default function PhoneList(props) {

     return (
        props.phones.map((phone) => (
            <div key={phone.id}>
                <SinglePhone number={phone.phone}/>
            </div>))
    )
}


export function SinglePhone(props) {

    const number = props.number?.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/,"($1) $2-$3-$4")

    const [ringOut] = useUpdateRingOutMutation()
    const { data: user, isLoading, isFetching } = useGetUserByphoneQuery(props.number)

    const userName = user ? ((user.lastname ? user.lastname : '') + ' ' + user.firstname) : undefined

    return (
        <>
            <Telephone size={20} className={s.phone} onClick={() => ringOut({ ring: 'out', number: props.number, name: userName })} /> {number}
        </>
    )
}