import { crmApi } from './api'

const ordersApi = crmApi.injectEndpoints({
  endpoints: (builder) => ({

    getOrders: builder.query({
      query: () => 'orders',
      providesTags: ['Order'],
    }),

    getOrderStatuses: builder.query({
      query: () => 'orders/statuses',
      providesTags: ['OrderStatus'],
    }),

    updateOrderStatuses: builder.mutation({
      query: (body) => ({
        url: 'orders/statuses',
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (result) => (result ? ['OrderStatus'] : []),
    }),

    getChannels: builder.query({
      query: () => 'orders/channels',
      providesTags: ['Channel'],
    }),

    updateChannels: builder.mutation({
      query: (body) => ({
        url: 'orders/channels',
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (result) => (result ? ['Channel'] : []),
    }),

    getDeliverys: builder.query({
      query: () => 'orders/deliverys',
      providesTags: ['Delivery'],
    }),

    updateDeliverys: builder.mutation({
      query: (body) => ({
        url: 'orders/deliverys',
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (result) => (result ? ['Delivery'] : []),
    }),

    getPayments: builder.query({
      query: () => 'orders/payments',
      providesTags: ['Payment'],
    }),

    updatePayments: builder.mutation({
      query: (body) => ({
        url: 'orders/payments',
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (result) => (result ? ['Payment'] : []),
    }),

  }),
})

export const {
  useGetOrdersQuery,
  useGetOrderStatusesQuery,
  useUpdateOrderStatusesMutation,
  useGetChannelsQuery,
  useUpdateChannelsMutation,
  useGetDeliverysQuery,
  useUpdateDeliverysMutation,
  useGetPaymentsQuery,
  useUpdatePaymentsMutation,
} = ordersApi