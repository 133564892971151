import { useGetUserQuery } from '../../app/api/usersApi'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams } from 'react-router-dom'
import { EnvelopeAt } from 'react-bootstrap-icons'
import { SpinnerBig } from '../../features/Spinner/Spinner'
import EditUser from '../../components/User/EditUser'
import PhoneList from './PhoneList'


export default function UserInfo() {

    const { id } = useParams()

    const { data: user, isLoading, isFetching } = useGetUserQuery(id)

    if (isLoading || isFetching) return <SpinnerBig />

    return (
        <div className='w-auto h-100 shadow p-3 rounded-3 border bg-white text-break'>
            <Row xs={3}>
                <Col className='text-secondary'>Прізвище:</Col>
                <Col>{user.lastname}</Col><Col><EditUser user={user}/></Col>
            </Row>
            <Row xs={3}>
                <Col className='text-secondary'>Ім'я:</Col>
                <Col>{user.firstname}</Col>
            </Row>
            <Row xs={3}>
                <Col className='text-secondary'>По батькові:</Col>
                <Col>{user.surname}</Col>
            </Row>



            <div><EnvelopeAt size={20}/> {user.email}</div>
            <div><PhoneList phones={user.UserPhone}/></div>
        </div>
    )
}

