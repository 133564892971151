import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Sidebarmenu from './SidebarMenu'
import Header from './Header'
import Footer from './Footer'
import CallWindow from '../../components/CallWindow/CallWindow'


export default function Layout() {

    const [toggled, setToggled] = useState(false)

    return (
        <Container fluid className='min-vh-100 bg-light'>
            <Header toggled={toggled} setToggled={setToggled}/>
            <Sidebarmenu toggled={toggled} setToggled={setToggled}/>
            <Footer />
            <CallWindow />
        </Container>
    );
}