import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { EnvelopeAt, PersonBoundingBox } from 'react-bootstrap-icons'
import PhoneList from './PhoneList'



export default function UsersList(props) {

    const isXS = useMediaQuery({ query: '(max-width: 575px)' })
    const isSM = useMediaQuery({ query: '(min-width: 576px)' })
    const isMD = useMediaQuery({ query: '(min-width: 768px)' })
    const isLG = useMediaQuery({ query: '(min-width: 992px)' })
    const isXL = useMediaQuery({ query: '(min-width: 1200px)' })
    const isXXL = useMediaQuery({ query: '(min-width: 1400px)' })

    let itemsPerPage, pageRangeDisplayed, marginPagesDisplayed, forcePage

    if (isXS) {
        itemsPerPage = 10
        pageRangeDisplayed = 1
        marginPagesDisplayed = 1
    }
    if (isSM) {
        itemsPerPage = 15
        pageRangeDisplayed = 1
        marginPagesDisplayed = 1
    }
    if (isMD) {
        itemsPerPage = 20
        pageRangeDisplayed = 2
        marginPagesDisplayed = 1
    }
    if (isLG) {
        itemsPerPage = 30
        pageRangeDisplayed = 2
        marginPagesDisplayed = 2
    }
    if (isXL) {
        itemsPerPage = 40
        pageRangeDisplayed = 3
        marginPagesDisplayed = 3
    }
    if (isXXL) {
        itemsPerPage = 50
        pageRangeDisplayed = 5
        marginPagesDisplayed = 3
    }

    const searchValue = props.search

    if (searchValue) {
        forcePage = 0
    }

    const filteredData = props.users.filter((item) => {
        if (!searchValue) return props.users
        return (
            item.lastname?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item.firstname?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item.surname?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item.email?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item.UserPhone?.some(phones => phones.phone?.includes(searchValue?.replace(/\D/g, '').slice(-10)))
        )
    })

    const [itemOffset, setItemOffset] = useState(0)
    const endOffset = itemOffset + itemsPerPage
    const currentItems = filteredData.slice(itemOffset, endOffset)
    const pageCount = filteredData ? Math.ceil(filteredData.length / itemsPerPage) : 0

    useEffect(() => {
        setItemOffset(0)
    }, [searchValue])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredData.length
        setItemOffset(newOffset)
        window.scrollTo(0, 0)
    };


    return (
        <>
            <Row xs={1} sm={1} md={2} lg={3} xl={4} xxl={5}>
                <Items currentItems={currentItems} />
            </Row>
            <Row className='mt-4'>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={pageRangeDisplayed}
                    marginPagesDisplayed={marginPagesDisplayed}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={forcePage}
                /></Row>
        </>
    );
}


function Items({ currentItems }) {
    return (
        <>
            {currentItems &&
                currentItems.map((item) => (
                    <Col key={item.id} className='py-2'>
                        <div className={'w-auto h-100 shadow p-3 rounded-3 border bg-white text-break hovereffect'}>
                            <div className='text-center'><PersonBoundingBox size={35} color='#adb5bd' /></div>
                            <div className='my-2'><Link to={`${item.id}`} className='text-decoration-none text-center'>
                                <div>{item.lastname} {item.firstname}</div>
                                {item.surname && <div>{item.surname}</div>}
                            </Link></div>
                            <PhoneList phones={item.UserPhone}/></div>
                    </Col>


                ))}
        </>
    );
}




