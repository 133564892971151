import { useGetProductsQuery } from '../../app/api/productsApi'
import ReactPaginate from 'react-paginate';
import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'



export default function ProductsPage() {

    //const { data: items, isLoading, isFetching } = useGetProductsQuery()


    // if (isLoading || isFetching) return <p>Loading ... </p>



    // const listItems = post?.map((number) =>
    //    <li key={number.id}>
    //        {number.id}  {number.sku} {number.name} </li>
    // )

    return (
        <Container fluid>
            product
            <Row  xs={1} sm={2} md={3} lg={4} xl={5} xxl={6}>
                <PaginatedItems itemsPerPage={40} />
            </Row>
        </Container>
    );
}


function Items({ currentItems }) {
    return (
        <>
            {currentItems &&
                currentItems.map((item) => (
                    <Col>
                    <div className='w-auto shadow p-3 mb-3 rounded border border-primary'><h3>Item #{item.id}</h3></div>
                    </Col>
                        
                   
                ))}
        </>
    );
}

function PaginatedItems({ itemsPerPage }) {


    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    const { data: items, isLoading, isFetching } = useGetProductsQuery()
    if (isLoading || isFetching) return <p>Loading ... </p>

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        window.scrollTo(0, 0)
    };

    return (
        <>
            <Items currentItems={currentItems} />
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
            />
        </>
    );
}