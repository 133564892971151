import Container from 'react-bootstrap/Container'
import Brands from '../../components/Brands/Brands'
import { useGetBrandsQuery } from '../../app/api/productsApi'
import { SpinnerBig } from '../../features/Spinner/Spinner'




export default function BrandsPage() {

    const { data: brands, isLoading, isFetching } = useGetBrandsQuery()

    const totalBrandCount = brands?.length || 0

    return (
        <Container fluid className='h-100'>
            <h5>Бренди ({totalBrandCount})</h5>
            {(isLoading || isFetching) ? <SpinnerBig /> :
                brands && <Brands brands={brands} />}
        </Container>
    )

}


