import { crmApi } from './api'

const callsApi = crmApi.injectEndpoints({

  endpoints: (builder) => ({

    updateRingOut: builder.mutation({
      query: (body) => ({
        url: `calls/ringout`,
        method: 'PUT',
        body: body,
      })
    }),

    getCalls: builder.query({
      query: () => 'calls',
    }),

    getUserByphone: builder.query({
      query: (number) => `calls/${number}/user`,
    }),

    getMissedCount: builder.query({
      query: () => 'calls/missed/count',
    }),

    getRingIn: builder.query({
      query: () => 'calls/ringin',
    }),

    delRingInCRM: builder.mutation({
      query: (number) => ({
        url: `calls/ringincrm/${number}`,
        method: 'DELETE',
      })
    }),

  }),
})



export const {
  useUpdateRingOutMutation,
  useGetCallsQuery,
  useGetUserByphoneQuery,
  useGetMissedCountQuery,
  useGetRingInQuery,
  useDelRingInCRMMutation,
} = callsApi