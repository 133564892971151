import { List } from 'react-bootstrap-icons'


export default function Header(props) {

    return (
        <><div className='d-inline-block d-sm-none'>
            {!props.toggled && <List size={30} role='button' onClick={() => props.setToggled(true)} />}
        </div>
            <div>Header</div>
        </>
    );
}