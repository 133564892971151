


export default function Footer() {


   
    return (
        <div >
            Footer
        </div>
    );
}