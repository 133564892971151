import Container from 'react-bootstrap/Container'
import PaymentType from '../../components/PaymentType/PaymentType'
import { useGetPaymentsQuery } from '../../app/api/ordersApi'
import { SpinnerBig } from '../../features/Spinner/Spinner'




export default function PaymentTypePage() {

    const { data: paymentType, isLoading, isFetching } = useGetPaymentsQuery()

    const totalPaymentTypeCount = paymentType?.length || 0

    return (
        <Container fluid className='h-100'>
            <h5>Способи оплати ({totalPaymentTypeCount})</h5>
            {(isLoading || isFetching) ? <SpinnerBig /> :
                paymentType && <PaymentType paymentType={paymentType} />}
        </Container>
    )

}


