import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { crmApi } from './api/api'
import authReducer from '../components/Login/authSlice'
import userReducer from '../components/User/userSlice'

export const store = configureStore({
  reducer: {
    [crmApi.reducerPath]: crmApi.reducer,
    auth: authReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(crmApi.middleware),
})

