import { useGetUserCallsQuery } from '../../app/api/usersApi'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams } from 'react-router-dom'
import { EnvelopeAt } from 'react-bootstrap-icons'
import { SpinnerBig } from '../../features/Spinner/Spinner'
import EditUser from '../../components/User/EditUser'
import PhoneList from './PhoneList'
import { TelephoneInboundFill, TelephoneOutboundFill, TelephoneXFill, PersonBoundingBox } from 'react-bootstrap-icons'
import { isToday, isYesterday, parseISO, format, intervalToDuration, formatDuration } from 'date-fns'
import { uk } from 'date-fns/locale'
import { SinglePhone } from '../User/PhoneList'


export default function UserCalls() {

    const { id } = useParams()

    const { data: calls, isLoading, isFetching } = useGetUserCallsQuery(id)

    if (isLoading || isFetching) return <SpinnerBig />

    return (
        <div className='w-auto h-100 shadow p-3 rounded-3 border bg-white text-break'>
            {calls &&
                calls.map((item) => (
                    <Col key={item.id} className='py-1'>
                        <div className={'w-auto h-100 shadow p-3 rounded-3 border bg-white hovereffect'}>
                            <Row>
                                <Col>
                                    {item.call === 'in' && <TelephoneInboundFill color='#0d6efd' size={25} />}
                                    {item.call === 'out' && <TelephoneOutboundFill color='#198754' size={25} />}
                                    {item.call === 'missed' && < TelephoneXFill color='#dc3545' size={25} />}
                                </Col>
                                <Col><SinglePhone number={item.number} /></Col>
                                <Col>{isToday(parseISO(item.createdAt)) && 'сьогодні'}
                                    {isYesterday(parseISO(item.createdAt)) && 'вчора'}
                                    {(!isToday(parseISO(item.createdAt)) && !isYesterday(parseISO(item.createdAt))) && format(parseISO(item.createdAt), 'd.MM.yyyy')}
                                    {' ' + format(parseISO(item.createdAt), 'H:mm')}
                                </Col>
                                <Col> {item.duration === 0 || !item.duration ? '' : formatDuration(intervalToDuration({ start: 0, end: item.duration * 1000 }), { locale: uk })}</Col>
                            </Row>
                        </div>
                    </Col>))}

        </div>
    )
}

