import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import s from './Login.module.css'
import { useForm } from "react-hook-form"
import { useLoginMutation } from '../../app/api/authApi'
import { useNavigate } from 'react-router-dom'
import SpinnerSmall from '../../features/Spinner/Spinner'
import ErrorMessage from '../../features/ErrorMessage/ErrorMessage'
import Cookies from 'js-cookie'


export default function Login() {
    const { register, handleSubmit } = useForm()
    const [login, { isLoading, isError, error }] = useLoginMutation()
    const navigate = useNavigate()

    const onSubmit = async (formdata) => {
        const payload = await login(formdata)
        if (payload.data) {
            Cookies.set('isLogged', 'true', { expires: 1 })
            navigate('/')
        }
    }

    return (
        <Row className='align-items-center'>
            <Col>
                <Form onSubmit={handleSubmit(onSubmit)} className={`mx-auto ${s.form}`}>
                    {isError && <ErrorMessage error={error} />}
                    <Form.Group className="mb-3">
                        <Form.Label className='text-secondary'>Логін</Form.Label>
                        <Form.Control {...register("login")} type="text" required />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label className='text-secondary'>Пароль</Form.Label>
                        <Form.Control {...register("password")} type="password" required />
                    </Form.Group>
                    <Button variant="primary" type="submit" className='mx-auto d-block' disabled={isLoading}>
                        {isLoading ? <SpinnerSmall /> : '🔑'} Увійти
                    </Button>
                </Form >
            </Col>
        </Row>
    )
}