import Spinner from 'react-bootstrap/Spinner'

export default function SpinnerSmall() {

    return (
        <Spinner animation="border" size="sm" />
    )
}

export function SpinnerSmallBlue() {

    return (
        <Spinner animation="border" variant="primary" size="sm" />
    )
}

export function SpinnerBig() {

    return (
        <div className='d-flex justify-content-center h-50'>
            <Spinner animation="border" variant="primary" className='my-auto ' />
        </div>
    )
}