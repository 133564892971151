import Container from 'react-bootstrap/Container'
import OrderStatuses from '../../components/OrderStatuses/OrderStatuses'
import { useGetOrderStatusesQuery } from '../../app/api/ordersApi'
import { SpinnerBig } from '../../features/Spinner/Spinner'




export default function OrderStatusesPage() {

    const { data: statuses, isLoading, isFetching } = useGetOrderStatusesQuery()

    const totalStatusesCount = statuses?.length || 0

    return (
        <Container fluid className='h-100'>
            <h5>Статуси замовлень ({totalStatusesCount})</h5>
            {(isLoading || isFetching) ? <SpinnerBig /> :
                statuses && <OrderStatuses statuses={statuses} />}
        </Container>
    )

}


