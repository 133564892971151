import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { TelephoneInboundFill, TelephoneOutboundFill, TelephoneXFill, PersonBoundingBox } from 'react-bootstrap-icons'
import { isToday, isYesterday, parseISO, format, intervalToDuration, formatDuration } from 'date-fns'
import { uk } from 'date-fns/locale'
import s from './CallsList.module.css'
import { SinglePhone } from '../User/PhoneList'
import { useGetUserByphoneQuery } from '../../app/api/callsApi'
import { SpinnerSmallBlue } from '../../features/Spinner/Spinner'
import DateTime from '../../features/DateTime/DateTime'
import UserByPhone from '../User/UserByPhone'




export default function CallsList(props) {

    const isXS = useMediaQuery({ query: '(max-width: 575px)' })
    const isSM = useMediaQuery({ query: '(min-width: 576px)' })
    const isMD = useMediaQuery({ query: '(min-width: 768px)' })
    const isLG = useMediaQuery({ query: '(min-width: 992px)' })
    const isXL = useMediaQuery({ query: '(min-width: 1200px)' })
    const isXXL = useMediaQuery({ query: '(min-width: 1400px)' })

    let itemsPerPage, pageRangeDisplayed, marginPagesDisplayed, forcePage

    if (isXS) {
        itemsPerPage = 10
        pageRangeDisplayed = 1
        marginPagesDisplayed = 1
    }
    if (isSM) {
        itemsPerPage = 20
        pageRangeDisplayed = 1
        marginPagesDisplayed = 1
    }
    if (isMD) {
        itemsPerPage = 30
        pageRangeDisplayed = 2
        marginPagesDisplayed = 1
    }
    if (isLG) {
        itemsPerPage = 40
        pageRangeDisplayed = 2
        marginPagesDisplayed = 2
    }
    if (isXL) {
        itemsPerPage = 50
        pageRangeDisplayed = 3
        marginPagesDisplayed = 3
    }
    if (isXXL) {
        itemsPerPage = 60
        pageRangeDisplayed = 5
        marginPagesDisplayed = 3
    }

    const searchValue = props.search
    const missCheck = props.checked

    if (searchValue) {
        forcePage = 0
    }

    const filteredData = props.calls.filter((item) => {
        if (!searchValue && !missCheck) return props.calls
        return (
            (searchValue ? item.number?.toLowerCase().includes(searchValue?.toLowerCase()) : true) &&
            (missCheck ? item.duration === 0 : true)
        )
    })

    const [itemOffset, setItemOffset] = useState(0)
    const endOffset = itemOffset + itemsPerPage
    const currentItems = filteredData.slice(itemOffset, endOffset)
    const pageCount = filteredData ? Math.ceil(filteredData.length / itemsPerPage) : 0

    useEffect(() => {
        setItemOffset(0)
    }, [searchValue])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredData.length
        setItemOffset(newOffset)
        window.scrollTo(0, 0)
    };


    return (
        <>
            <Row xs={1}>
                <Items currentItems={currentItems} />
            </Row>
            <Row className='mt-4'>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={pageRangeDisplayed}
                    marginPagesDisplayed={marginPagesDisplayed}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={forcePage}
                /></Row>
        </>
    );
}


function Items({ currentItems }) {

    return (
        <>
            <Row className='text-center d-none d-md-flex'>
                <Col xs={12} md={1}><Form.Label className='text-secondary'>Виклик</Form.Label></Col>
                <Col xs={12} md={2}><Form.Label className='text-secondary'>Номер</Form.Label></Col>
                <Col xs={12} md={3}><Form.Label className='text-secondary'>Клієнт</Form.Label></Col>
                <Col xs={12} md={2}><Form.Label className='text-secondary'>Дата</Form.Label></Col>
                <Col xs={12} md={2}><Form.Label className='text-secondary'>Тривалість</Form.Label></Col>
                <Col xs={12} md={2}><Form.Label className='text-secondary'>Канал</Form.Label></Col>
            </Row>
            {currentItems &&
                currentItems.map((item) => (
                    <Col key={item.id} className='py-1'>
                        <div className={'w-auto h-100 shadow p-3 rounded-3 border bg-white hovereffect'}>
                            <Row className='text-center'>
                                <Col xs={12} md={1}>
                                    {item.call === 'in' && <TelephoneInboundFill color='#0d6efd' size={25} />}
                                    {item.call === 'out' && <TelephoneOutboundFill color='#198754' size={25} />}
                                    {item.call === 'missed' && item.duration === 0 && < TelephoneXFill color='#dc3545' size={25} className={s.blink} />}
                                    {item.call === 'missed' && item.duration !== 0 && < TelephoneXFill color='#dc3545' size={25} />}
                                </Col>
                                <Col xs={12} md={2}><SinglePhone number={item.number} /></Col>
                                <Col xs={12} md={3}><UserByPhone number={item.number} /></Col>
                                <Col xs={12} md={2}><DateTime date={item.createdAt} /></Col>
                                <Col xs={12} md={2}> {item.duration === 0 || !item.duration ? '' : formatDuration(intervalToDuration({ start: 0, end: item.duration * 1000 }), { locale: uk })}</Col>
                                <Col xs={12} md={2}> {item.phoneid}</Col>
                            </Row>
                        </div>
                    </Col>
                ))
            }
        </>
    );
}