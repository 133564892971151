import React, { useState } from 'react'
import Badge from 'react-bootstrap/Badge'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { TelephoneInboundFill, PersonBoundingBox } from 'react-bootstrap-icons'
import { useGetRingInQuery, useDelRingInCRMMutation, useGetUserByphoneQuery } from '../../app/api/callsApi'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useEffect } from 'react'
import CloseButton from 'react-bootstrap/CloseButton'
import s from './CallWindow.module.css'
import AddUser from '../User/AddUser'



export default function CallWindow() {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { data: ring } = useGetRingInQuery(undefined, {
        selectFromResult: ({ data }) => ({
            data: data
        }),
        pollingInterval: 1000,
    })

    const { data: user, isLoading, isFetching } = useGetUserByphoneQuery(ring?.number)

    const [delRing] = useDelRingInCRMMutation()


    useEffect(() => {
        setShow(true)
    }, [ring])




    return (
        <>
            {ring && !show &&
                <div className={s.fixdown}>
                    <Badge className={s.pointer} variant="primary" onClick={handleShow}>
                        <TelephoneInboundFill size={40} />
                    </Badge>
                    <CloseButton className='align-top' onClick={() => delRing(ring.number)} />
                </div>
            }
            {ring &&
                <Offcanvas show={show} onHide={handleClose} placement='end' scroll >
                    <Offcanvas.Header closeButton closeVariant='white' className=' bg-primary bg-gradient text-white'>
                        <Offcanvas.Title className=''><TelephoneInboundFill size={25} className='me-3' />Вхідний виклик ({ring?.phoneid})</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Row >
                            <Col className='fs-4 text-center'>{ring.number.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "($1) $2-$3-$4")}</Col>
                        </Row>

                        <Row >
                            {user ?
                                <Col className='fs-4 text-center'>
                                    <Link to={`/user/${user.id}`} className='text-decoration-none text-center'>
                                        <PersonBoundingBox size={35} color='#adb5bd' className='mx-2' />{(user.lastname ? user.lastname : '') + ' ' + user.firstname}
                                    </Link></Col>

                                :
                                <Col className='text-center mt-3'>
                                    <AddUser number={ring.number} />
                                </Col>
                            }
                        </Row>
                    </Offcanvas.Body>
                </Offcanvas>
            }

        </>
    );
}