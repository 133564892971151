import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import UserInfo from '../../components/User/UserInfo'
import UserCalls from '../../components/User/UserCalls'


export default function UserPage() {

    return (
        <Container fluid className='h-100'>
            <Row xs={1} sm={2}>
                <Col><UserInfo /></Col>
                <Col><UserCalls/></Col>
            </Row>
        </Container>
    )

}
