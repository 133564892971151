import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'



export default function OrderPage() {

    return (
        <Container fluid className='h-100'>
            <Row xs={1} sm={2}>
                <Col>1</Col>
                <Col>2</Col>
            </Row>
        </Container>
    )

}
