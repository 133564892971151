import { createSlice } from '@reduxjs/toolkit';


const initialState = {
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    //logout: () => initialState,
  },
});

export const { setSearchValue } = userSlice.actions;
export default userSlice.reducer;
