import { useState } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { Outlet, NavLink, useLocation } from 'react-router-dom'
import {
  PeopleFill, CartCheckFill, HouseDoorFill, BoxFill, TelephoneFill,
  XLg, ChevronDoubleLeft, ChevronDoubleRight, GearFill,
} from 'react-bootstrap-icons'
import logosm from '../../assets/images/crm_logo_sm.png'
import MissCallCollapsBadge, { MissCallBadge } from '../../features/Badge/Badge'




export default function SidebarMenu(props) {

  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false)


  return (
    <div style={{ display: 'flex', height: '100%', minHeight: '90vh' }}>
      <Sidebar collapsed={collapsed} toggled={props.toggled} onBackdropClick={() => props.setToggled(false)} breakPoint="sm" backgroundColor="#264785" width='210px' collapsedWidth='80px' marginRight='20px'>
        <Menu closeOnClick='true' menuItemStyles={{
          button: ({ level, active, disabled, open }) => {
            if (level === 0) {
              return {
                color: active ? '#264785' : '#f8f9fa',
                backgroundColor: active ? '#f8f9fa' : '#264785',
                borderLeftColor: '#264785',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
                marginLeft: '5px',
                ":hover": {
                  backgroundColor: '#ffffff50',
                },
              }
            }
            if (level === 1) {
              return {
                color: active ? '#264785' : '#f8f9fa',
                backgroundColor: active ? '#f8f9fa' : '#264785',
                borderLeftColor: '#264785',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
                marginLeft: collapsed ? '5px' : '25px',
                paddingLeft: '20px',
                ":hover": {
                  backgroundColor: '#ffffff50',
                },
              }
            }
          },
          subMenuContent: ({ level, open, isSubmenu }) => {
            if (isSubmenu) {
              return {
                backgroundColor: '#264785',
                borderColor: '#264785',
                borderStyle: collapsed ? 'solid' : 'none',
              }
            }
          },
        }}
        >
          <div className='d-inline-flex w-100'>
            <div className='d-flex w-50 m-2'>
              {props.toggled && (<XLg size={20} color='white' role="button" onClick={() => props.setToggled(false)} />)}
            </div>
            <div className='d-flex flex-row-reverse w-50 m-2'>
              {collapsed ? <ChevronDoubleRight size={20} color='white' role="button" onClick={() => setCollapsed(false)} /> :
                <ChevronDoubleLeft size={20} color='white' role="button" onClick={() => setCollapsed(true)} />}
            </div>
          </div>

          <MenuItem
            active={location.pathname === "/" && 'true'}
            component={<NavLink to="/" />}
            onClick={() => props.setToggled(false)}
            icon={<HouseDoorFill size={25} />}>
            Home
          </MenuItem>

          <MenuItem
            active={location.pathname === "/order" && 'true'}
            component={<NavLink to="order" />}
            onClick={() => props.setToggled(false)}
            icon={<CartCheckFill size={25} />}>
            Замовлення
          </MenuItem>

          <MenuItem
            active={location.pathname === "/call" && 'true'}
            component={<NavLink to="call" />}
            onClick={() => props.setToggled(false)}
            icon={<div><TelephoneFill size={25} /> {collapsed && <MissCallCollapsBadge />}</div>}
            suffix={!collapsed && <MissCallBadge />}>
            Дзвінки
          </MenuItem>

          <MenuItem
            active={location.pathname === "/user" && 'true'}
            component={<NavLink to="user" />}
            onClick={() => props.setToggled(false)}
            icon={<PeopleFill size={25} />} >
            Клієнти
          </MenuItem>

          <MenuItem
            active={location.pathname === "/product" && 'true'}
            component={<NavLink to="product" />}
            onClick={() => props.setToggled(false)}
            icon={<BoxFill size={25} />}>
            Товари
          </MenuItem>

          <SubMenu
            active={location.pathname.includes('settings') & collapsed}
            icon={<GearFill size={25} />} label='Налаштування'>

            <MenuItem
              active={location.pathname === "/settings/brand" && 'true'}
              component={<NavLink to="settings/brand" />}
              onClick={() => props.setToggled(false)}>
              Бренди
            </MenuItem>

            <MenuItem
              active={location.pathname === "/settings/order-status" && 'true'}
              component={<NavLink to="settings/order-status" />}
              onClick={() => props.setToggled(false)}>
              Статуси замовлень
            </MenuItem>

            <MenuItem
              active={location.pathname === "/settings/payment" && 'true'}
              component={<NavLink to="settings/payment" />}
              onClick={() => props.setToggled(false)}>
              Способи оплати
            </MenuItem>

            <MenuItem
              active={location.pathname === "/settings/delivery" && 'true'}
              component={<NavLink to="settings/delivery" />}
              onClick={() => props.setToggled(false)}>
              Способи доставки
            </MenuItem>

            <MenuItem
              active={location.pathname === "/settings/channel" && 'true'}
              component={<NavLink to="settings/channel" />}
              onClick={() => props.setToggled(false)}>
              Канали продажів
            </MenuItem>

            <MenuItem
              active={location.pathname === "/product" && 'true'}
              component={<NavLink to="product" />}
              onClick={() => props.setToggled(false)}>
              Категорії
            </MenuItem>

          </SubMenu>

        </Menu>
        <img src={logosm} alt='logo' className='w-75 mx-auto my-4 d-block' style={{ opacity: 0.1 }} />
      </Sidebar>
      <main className='w-100'><Outlet /></main>
    </div >
  );
}
