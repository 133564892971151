import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import LoginPage from './pages/Login/LoginPage'
import Layout from './pages/Layout/Layout'
import HomePage from './pages/Home/HomePage'
import OrdersPage from './pages/Orders/OrdersPage'
import OrderPage from './pages/Orders/OrderPage'
import ProductsPage from './pages/Products/ProductsPage'
import UsersPage from './pages/Users/UsersPage'
import UserPage from './pages/Users/UserPage'
import BrandsPage from './pages/Settings/BrandsPage'
import CallsPage from './pages/Calls/CallsPage'
import OrderStatusesPage from './pages/Settings/OrderStatusePage'
import DeliveryTypePage from './pages/Settings/DeliveryTypePage'
import PaymentTypePage from './pages/Settings/PaymentTypePage'
import ChannelsPage from './pages/Settings/СhannelsPage'
import Cookies from 'js-cookie'





function App() {
  const isLogged = Cookies.get('isLogged')
  const location = useLocation()

  if (!isLogged && location.pathname !== '/login') {
    return <Navigate to='login' />;
  }

  return (
    <>
      <Routes>
        <Route path='login' element={<LoginPage />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="order" element={<OrdersPage />} />
          <Route path="order/:id" element={<OrderPage />} />
          <Route path="user" element={<UsersPage />} />
          <Route path="user/:id" element={<UserPage />} />
          <Route path="product" element={<ProductsPage />} />
          <Route path="settings/brand" element={<BrandsPage />} />
          <Route path="settings/payment" element={<PaymentTypePage />} />
          <Route path="settings/delivery" element={<DeliveryTypePage />} />
          <Route path="settings/order-status" element={<OrderStatusesPage />} />
          <Route path="settings/channel" element={<ChannelsPage />} />
          <Route path="call" element={<CallsPage />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
