import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useForm, useFieldArray } from "react-hook-form"
import { useUpdateBrandsMutation } from '../../app/api/productsApi'
import Button from 'react-bootstrap/Button'
import { PlusSquare } from 'react-bootstrap-icons'
import SpinnerSmall from '../../features/Spinner/Spinner'
import ErrorMessage from '../../features/ErrorMessage/ErrorMessage'


export default function Brands(props) {

    const { register, handleSubmit, control } = useForm({
        defaultValues: { brands: props.brands }
    })

    const { fields, append } = useFieldArray({ control, name: "brands" })

    const [updateBrands, { isLoading, isError, error }] = useUpdateBrandsMutation()

    const onSubmit = (formdata) => {
        updateBrands(formdata).unwrap()
            //.then((playload) => console.log(playload))
            .catch((error) => console.log(error));
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} >
            {isError && <ErrorMessage error={error} />}
            <Form.Group>
                <Row>
                    <Col md="4" xl="3"><Form.Label className='text-secondary'>Бренд *</Form.Label></Col>
                    <Col md="2" xl="1"><Form.Label className='text-secondary'>Soccerpoint</Form.Label></Col>
                    <Col md="2" xl="1"><Form.Label className='text-secondary'>Prom</Form.Label></Col>
                </Row>
                {fields.map((item, index) => (
                    <Row key={item.id} >
                        <Col md="4" xl="3"><Form.Control className="mb-2"
                            {...register(`brands.${index}.name`)} type="text" required /></Col>
                        <Col md="2" xl="1"><Form.Check className="my-2"
                            {...register(`brands.${index}.soccerpoint`)} type="switch" /></Col>
                        <Col md="2" xl="1"><Form.Check className="my-2"
                            {...register(`brands.${index}.prom`)} type="switch" /></Col>
                    </Row>
                ))}
                <Button variant="light" className='text-secondary mb-4' onClick={() => append()}><PlusSquare size={20} /> Додати бренд</Button>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? <SpinnerSmall /> : '✔️'} Зберегти
            </Button>
        </Form >
    );
}
