import Login from "../../components/Login/Login";
import logo from '../../assets/images/crm_logo.png';
import s from './LoginPage.module.css';
import Container from 'react-bootstrap/Container';




export default function LoginPage() {
    return (
        <Container>
            <img src={logo} className={`mx-auto m-5 d-block ${s.logo}`} alt="Logo" />
            <Login />
        </Container>

    );
}