import { crmApi } from './api'

const productsApi = crmApi.injectEndpoints({
  endpoints: (builder) => ({

    getProducts: builder.query({
      query: () => 'products',
    }),

    getBrands: builder.query({
      query: () => 'products/brands',
      providesTags: ['Brand'],
    }),


    updateBrands: builder.mutation({
      query: (body) => ({
        url: 'products/brands',
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (result) => (result ? ['Brand'] : []),
    }),

  }),
})

export const {
  useGetProductsQuery,
  useGetBrandsQuery,
  useUpdateBrandsMutation,
} = productsApi