import Alert from 'react-bootstrap/Alert'

export default function ErrorMessage(props) {

    let errorMassage = ''

     if (props.error.data?.error) {
        errorMassage = props.error.data.error
    }

    if (props.error.data?.error?.code) {
        errorMassage = props.error.data.error.code
    }

    if (props.error.error) {
        errorMassage = props.error.error
    }


    return (
        <Alert variant='warning' className="text-center">{errorMassage}</Alert>
    )
    
}