import { useGetOrdersQuery } from '../../app/api/ordersApi'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import OrdersList from '../../components/Orders/OrdersList'
import Search from '../../features/Search/Search'
import { SpinnerBig } from '../../features/Spinner/Spinner'
import { useState } from 'react'
import { CartCheckFill, ArrowRepeat } from 'react-bootstrap-icons'


export default function OrdersPage() {

    const { data: orders, isLoading, isFetching } = useGetOrdersQuery()

    const [searchValue, setSearchValue] = useState()


    return (
        <Container fluid className='h-100'>
            <Row xs="auto" className='mb-3'>
                <Col><h5><CartCheckFill size={30} /> Замовлення</h5></Col>
            </Row>
            <Row>
                <Col className='mb-4'
                    xs={{ span: 12, order: 'last' }}
                    sm={{ span: 12, order: 'last' }}
                    md={{ span: 6, order: 'first' }}
                    lg={5} xl={4} xxl={3}>
                    <Search setSearch={setSearchValue} />
                </Col>
                <Col className='text-end mb-4'>
                    AddOrder
                </Col>
            </Row>
            {
                (isLoading || isFetching) ? <SpinnerBig /> :
                    orders && <OrdersList orders={orders} search={searchValue} />
            }
        </Container >
    )

}
