import { useGetCallsQuery } from '../../app/api/callsApi'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import CallsList from '../../components/Calls/CallsList'
import Search from '../../features/Search/Search'
import { SpinnerBig } from '../../features/Spinner/Spinner'
import { useState } from 'react'
import { TelephoneFill, ArrowRepeat } from 'react-bootstrap-icons'
import Form from 'react-bootstrap/Form'


export default function CallsPage() {

    const { data: calls, isLoading, isFetching } = useGetCallsQuery()

    const [searchValue, setSearchValue] = useState()
    const [checked, setChecked] = useState(false);


    return (
        <Container fluid className='h-100'>
            <Row xs="auto" className='mb-3'>
                <Col><h5><TelephoneFill size={27} /> Дзвінки</h5></Col>
            </Row>
            <Row>
                <Col className='mb-4'
                    xs={7} sm={6} md={4} lg={3} xl={3} xxl={2}>
                    <Search setSearch={setSearchValue} />
                </Col>
                <Col className='mb-4 mt-2'>
                    <Form.Check type="switch" label="Пропущені виклики" onChange={() => setChecked(!checked)} />
                </Col>
            </Row>
            {
                (isLoading || isFetching) ? <SpinnerBig /> :
                    calls && <CallsList calls={calls} search={searchValue} checked={checked} />
            }
        </Container >
    )

}
