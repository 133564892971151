import Form from 'react-bootstrap/Form'


export default function Search(props) {


    return (
        <Form.Control
            type="search"
            placeholder='Пошук...'
            onChange={(e) => props.setSearch(e.target.value)}
        />
    )
}