import Container from 'react-bootstrap/Container'
import DeliveryType from '../../components/DeliveryType/DeliveryType'
import { useGetDeliverysQuery } from '../../app/api/ordersApi'
import { SpinnerBig } from '../../features/Spinner/Spinner'




export default function DeliveryTypePage() {

    const { data: deliveryType, isLoading, isFetching } = useGetDeliverysQuery()

    const totalDeliveryTypeCount = deliveryType?.length || 0


    return (
        <Container fluid className='h-100'>
            <h5>Способи доставки ({totalDeliveryTypeCount})</h5>
            {(isLoading || isFetching) ? <SpinnerBig /> :
                deliveryType && <DeliveryType deliveryType={deliveryType} />}
        </Container>
    )

}


